.waves {
  position: fixed;
  bottom: 40vh;
}
.fill {
  position: fixed;
  bottom: 0;
  height: 40vh;
  width: 100vw;
  background-color: rgb(159, 29, 29);
}
.container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  * {
    transition: ease 0.2s all;
  }
  .icon {
    margin-left: 10px;
    height: 0.9em;
  }
  .modal {
    background-color: #1b1b1b;
    padding: 30px;
    border-radius: 6px;
    border: solid white 2px;
    .commit {
      font-family: "Courier New", Courier, monospace;
      color: rgb(95, 95, 166);
      margin-top: 5px;
    }
    h2 {
      margin: 0;
    }
    h4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0;
      * {
        padding: 2px 10px;
        box-sizing: border-box;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
      span {
        background-color: rgb(64, 119, 64);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        flex: 1 1 20%;
      }
      input {
        background-color: rgba(255, 255, 255, 0.07);
        color: white;
        border-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        flex: 1 1 80%;
        &:focus {
          background-color: rgba(255, 255, 255, 0.275);
        }
      }
    }
    h4.network > span {
      height: 30px;
    }
    select {
      width: 100%;
      height: 30px;
      border: none;
      outline: none;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: rgba(255, 255, 255, 0.07);
      color: white;
      font-weight: bold;
    }
    .load {
      display: flex;
      & > * {
        flex: 1 1 30%;
      }
    }
    .import {
      border: solid rgb(64, 119, 64) 2px;
      border-radius: 6px;
      margin: 5px;
      margin-left: 20px;
      padding: 0;
      background-color: rgba(255, 255, 255, 0.07);
      span {
        width: 100%;
        display: block;
        border-radius: 0;
      }
      input {
        background-color: transparent;
        width: 220px;
        padding: 2px;
        border-radius: 0;
      }
    }
    button {
      font-size: 1.4em;
      background-color: transparent;
      height: 58px;
      border-color: rgb(64, 119, 64);
      font-weight: bold;
      display: flex;
      align-items: center;
      .icon {
        height: 1.4em;
      }
      &:hover {
        background-color: rgb(64, 119, 64);
      }
    }
  }
}
