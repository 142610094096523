.status {
  position: fixed;
  bottom: 0;
  right: 0;
  font-family: monospace;
  background-color: rgba(0, 0, 0, 0.366);
  font-size: 0.8em;
  span {
    margin-right: 10px;
  }
  button {
    border: none;
    text-align: center;
    border-left: solid white 1px;
    border-radius: 0;
    font-size: inherit;
    font-family: monospace;
    color: rgb(116, 116, 255);
    padding: 5px 15px;
    &:hover {
      background-color: rgb(116, 116, 255);
      color: black;
    }
  }
}
