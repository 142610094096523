.myNode {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  border-radius: 6px;
  font-weight: bold;
  flex-wrap: wrap;
  font-size: 1.5em;
  .row {
    flex-basis: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .full {
    display: none;
    position: absolute;
    top: 50px;
    position: fixed 
  }
  &:hover .full {
    display: flex;
  }
}
.icon {
  height: 1.5em;
}