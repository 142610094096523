html,
body {
  margin: 0;
  padding: 0;
  // 
  height: 100%;
  
  background: rgb(31,31,31);
  background: linear-gradient(35deg, rgb(15, 15, 15) 50%, rgb(2, 1, 33) 100%); 
  color: white;
  overflow: hidden;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
body {
  background-image: url("./constellation.svg");
}
input {
  font-size: inherit;
  border: none;
  outline: none;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.144);
  border-radius: 4px;
  color: rgb(94, 94, 255);
}
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(32, 32, 77);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
button {
  border: solid rgb(255, 255, 255) 2px;
  border-radius: 5px;
  outline: none;
  background: transparent;
  color: inherit;
  font-size: 1em;
  transition: ease 0.2s all;
  padding: 0.3em;
  &:hover {
    cursor: pointer;
    border-color: rgb(122, 122, 255);
  }
}
pre {
  margin: 0;
  display: inline;
  padding: 0 5px;
  box-sizing: border-box;
  color: rgb(138, 240, 138);
}
.node {
  color: pink;
}
.icon {
  margin: 0 5px;
}
