.contextMenu {
  background-color: rgba(0, 0, 0, 0.607);
  color: white;
  padding: 5px;
  min-width: 150px;
  font-size: 14px;
  border-radius: 4px;
  hr {
    color: rgb(130, 130, 130);
    border: none;
    background-color: rgb(82, 82, 82);
    height: 1px;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  * {
    transition: ease 0.2s all;
  }
  div {
    padding: 3px 2px;
    &:hover {
      cursor: pointer;
      padding-left: 5px;
      background-color: rgba(61, 61, 255, 0.238);
    }
  }
  .address {
    font-weight: bold;
  }
  .icon {
    margin-right: 5px;
    min-width: 15px;
  }
}
