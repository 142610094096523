.nav {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.687);
  width: 100vw;
  button {
    margin: 5px;
  }
  .dropdownmenu {
    background-color: rgba(0, 0, 0, 0.755);
    width: 200px;
    padding: 10px;
    display: none;
    flex-wrap: wrap;
    position: absolute;
    justify-content: left;
    align-items: center;
    input {
      max-width: 200px;
    }
    & > div {
      flex: 1 1 100%;
      padding: 5px;
      cursor: pointer;
      &:hover {
        padding-left: 10px;
      }
    }
  }
  .dropdownbutton:hover > .dropdownmenu {
    display: flex;
  }
}
