.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editor {
  .icon:hover {
    color: green;
    cursor: pointer;
  }
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.856);
  padding: 10px;
  border-radius: 6px;
  .closeBar {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 2em;
    }
    .icon {
      height: 20px;
    }
  }
  div {
    padding: 5px;
    flex: 1 1 100%;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    input {
      background-color: rgba(255, 255, 255, 0.103);
      margin: 5px 0 0 5px;
      color: white;
    }
    .edit {
      position: absolute;
      margin: 0;
      right: 45px;
      top: 15px;
      height: 15px;
    }
  }
}
